import React, { useState, useEffect, useCallback } from "react"
import { Box, Button } from "@chakra-ui/react"
import { ArrowUpIcon } from "@chakra-ui/icons"

const Scroll = ({ showBelow }) => {
  const [isVisible, setIsVisible] = useState(showBelow ? false : true)

  const scrollToTop = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  const toggleVisibility = useCallback(() => {
    if (window.pageYOffset > showBelow) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }, [showBelow])

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility)
    return () => {
      window.removeEventListener("scroll", toggleVisibility)
    }
  }, [toggleVisibility])

  return (
    <Box
      position="fixed"
      bottom={{ base: 0, md: 15 }}
      right={{ base: 0, md: 15 }}
      zIndex="10"
    >
      {isVisible && (
        <Button
          onClick={scrollToTop}
          size="md"
          _hover={{
            backgroundColor: "white",
            color: "#2777b8",
            border: "1px solid #2777b8;",
          }}
          rightIcon={<ArrowUpIcon  w={8} h={8} />}
          backgroundColor="#2777b8"
          color="#fff;"
          border="1px solid #2777b8;"
        >
        </Button>
      )}
    </Box>
  )
}

export default Scroll
