import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Box,
  Link,
  HStack,
  Text,
  SimpleGrid,
  VStack,
  Grid,
  GridItem,
  Image,
} from "@chakra-ui/react"
import { navigate } from "@reach/router"
import { useTranslation } from "gatsby-plugin-react-i18next"

import YaraLogo from "@/assets/images/yara-logo.svg"
import { sectionHyperlinks } from "@/utils/userresearch/constant"

const query = graphql`
  {
    footerBackgroundImage: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "footer-background.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          placeholder: TRACED_SVG
          layout: CONSTRAINED
          width: 1600
          quality: 80
          transformOptions: { fit: COVER }
        )
      }
    }
  }
`

const Footer = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query)

  return (
    <Box as="footer" width="full" color="white">
      {/* Upper */}
      <Box width="full" backgroundColor="green" position="relative">
        <Box
          as={GatsbyImage}
          image={getImage(data.footerBackgroundImage)}
          position="absolute"
          loading="lazy"
          width="full"
          height="full"
          alt="footer background"
        />
        <SimpleGrid column="4" position="relative" width="full" height="full">
          <Box width={{ base: "50%", lg: "25%" }} pos="relative">
            <Box
              position="absolute"
              width="full"
              height="full"
              backgroundColor="ahuaBlue.90"
              opacity="0.75"
            />
            <VStack
              position="relative"
              width="full"
              maxWidth="fit-content"
              marginX="auto"
              height="full"
              alignItems="flex-start"
              spacing="6"
              px={{ base: "6", lg: "12" }}
              py="20"
            >
              {sectionHyperlinks.map(({ id, label, href }, index) => (
                <Link
                  key={`footer-link-${index + 1}`}
                  onClick={() => navigate(href)}
                  borderRadius="md"
                >
                  {t(`common.menu-item.${id}`, label)}
                </Link>
              ))}
            </VStack>
          </Box>
        </SimpleGrid>
      </Box>

      {/* Lower */}
      <Box width="full" backgroundColor="ahuaBlue.90">
        <Box maxWidth="6xl" mx="auto" px="6" py={{ base: 10, md: 16 }}>
          <Grid
            alignItems="center"
            width="full"
            templateColumns={{ base: "unset", md: "repeat(2, 1fr)" }}
            rowGap="6"
          >
            <GridItem>
              <HStack spacing="6">
                <Image
                  alignSelf={{ base: "flex-start", md: "center" }}
                  src={YaraLogo}
                  boxSize={{ base: "50px", md: "60px" }}
                  alt="yara logo"
                />
                <Text
                  fontSize="sm"
                  dangerouslySetInnerHTML={{
                    __html: t("layout.footer.copyright", {
                      currentYear: new Date().getFullYear(),
                    }),
                  }}
                />
              </HStack>
            </GridItem>
            <GridItem
              rowStart={{ base: 1, md: "unset" }}
              justifySelf={{ base: "self-start", md: "self-end" }}
              width="full"
            >
              <HStack
                spacing="6"
                justifyContent={{ base: "space-between", md: "flex-end" }}
                width="full"
              >
                <Link
                  href="https://www.yara.com/privacy-and-legal/digital-farming-privacy/"
                  isExternal
                >
                  {t("layout.footer.privacy-policy")}
                </Link>
                <Link
                  href="https://www.yara.com/privacy-and-legal/digital-farming-terms/"
                  isExternal
                >
                  {t("layout.footer.term-of-use")}
                </Link>
              </HStack>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
