import React from "react"
import { Box ,ChakraProvider, extendTheme} from "@chakra-ui/react"

import Header from "@/components/UserResearch/Header"
import Footer from "@/components/UserResearch/Footer"
import Scroll from "@/components/Scroll"
import baseCustomTheme from "@/@chakra-ui/gatsby-plugin/theme"

const thailandTheme = extendTheme(
  {
    fonts: {
      heading: "'Max', sans-serif",
      body: "'Noto Sans', sans-serif",
    },
  },
  baseCustomTheme
)

const Layout = ({ children }) => {
  return (
    <ChakraProvider theme={thailandTheme}>
      <Header />
      <Box as="main" width="full">
        {children}
      </Box>
      <Footer />
      <Scroll showBelow={250} />
    </ChakraProvider>
  )
}

export default Layout
